/**
* General Uppy styles that apply to everything inside the .Uppy container
*/
.uppy-Root {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  position: relative;
  color: #333; }

.uppy-Root *, .uppy-Root *:before, .uppy-Root *:after {
  box-sizing: inherit; }

.uppy-Root [hidden] {
  display: none; }

.UppyIcon {
  max-width: 100%;
  max-height: 100%;
  fill: currentColor;
  /* no !important */
  display: inline-block;
  overflow: hidden; }

.UppyIcon--svg-baseline {
  bottom: -0.125em;
  position: relative; }

.uppy-u-reset {
  -webkit-appearance: none;
  line-height: 1;
  padding: 0;
  margin: 0;
  border: 0;
  color: inherit;
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
  background: none;
  border: medium none currentColor;
  border-collapse: separate;
  -webkit-border-image: none;
          border-image: none;
  border-radius: 0;
  border-spacing: 0;
  box-shadow: none;
  clear: none;
  cursor: auto;
  display: inline;
  empty-cells: show;
  float: none;
  font-family: inherit;
  font-size: inherit;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  left: auto;
  letter-spacing: normal;
  list-style: none;
  margin: 0;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  opacity: 1;
  outline: medium none invert;
  overflow: visible;
  overflow-x: visible;
  overflow-y: visible;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  top: auto;
  -webkit-transform: none;
          transform: none;
  -webkit-transform-origin: 50% 50% 0;
          transform-origin: 50% 50% 0;
  -webkit-transform-style: flat;
          transform-style: flat;
  -webkit-transition: none 0s ease 0s;
  transition: none 0s ease 0s;
  unicode-bidi: normal;
  vertical-align: baseline;
  visibility: visible;
  white-space: normal;
  z-index: auto; }

.uppy-c-textInput {
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5;
  padding: 6px 8px;
  background-color: #fff; }

.uppy-size--md .uppy-c-textInput {
  padding: 8px 10px; }

.uppy-c-textInput:focus {
  border-color: rgba(34, 117, 215, 0.6);
  outline: none;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.15); }

.uppy-c-btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  font-family: inherit;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.uppy-c-btn:not(:disabled):not(.disabled) {
  cursor: pointer; }

.uppy-c-btn::-moz-focus-inner {
  border: 0; }

.uppy-c-btn-primary {
  font-size: 14px;
  padding: 10px 18px;
  border-radius: 4px;
  background-color: #2275d7;
  color: #fff; }

.uppy-size--md .uppy-c-btn-primary {
  padding: 13px 22px; }

.uppy-c-btn-primary:hover {
  background-color: #1b5dab; }

.uppy-c-btn-primary:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.4); }

.uppy-c-btn-link {
  font-size: 14px;
  line-height: 1;
  padding: 10px 15px;
  border-radius: 4px;
  background-color: transparent;
  color: #525252; }

.uppy-size--md .uppy-c-btn-link {
  padding: 13px 18px; }

.uppy-c-btn-link:hover {
  color: #333; }

.uppy-c-btn-link:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.25); }

.uppy-c-btn--small {
  font-size: 0.9em;
  padding: 7px 16px;
  border-radius: 2px; }

.uppy-size--md .uppy-c-btn--small {
  padding: 8px 10px;
  border-radius: 2px; }

.uppy-Informer {
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 1;
  -webkit-transform: none;
          transform: none;
  -webkit-transition: all 250ms ease-in;
  transition: all 250ms ease-in;
  z-index: 1005; }

.uppy-Informer[aria-hidden=true] {
  opacity: 0;
  -webkit-transform: translateY(350%);
          transform: translateY(350%);
  -webkit-transition: all 300ms ease-in;
  transition: all 300ms ease-in;
  z-index: -1000; }

.uppy-Informer p {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 1.4;
  font-weight: 400;
  padding: 6px 15px;
  background-color: #757575;
  /* no !important */
  color: #fff;
  border-radius: 18px;
  max-width: 90%; }
  .uppy-size--md .uppy-Informer p {
    font-size: 14px;
    line-height: 1.3;
    max-width: 500px;
    padding: 10px 20px; }

.uppy-Informer span {
  line-height: 12px;
  width: 13px;
  height: 13px;
  display: inline-block;
  vertical-align: middle;
  color: #525252;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  top: -1px;
  left: 3px;
  font-size: 10px;
  margin-left: -1px; }

.uppy-Informer span:hover {
  cursor: help; }

.uppy-Informer span:after {
  line-height: 1.3;
  word-wrap: break-word; }

/* -------------------------------------------------------------------
  Microtip

  Modern, lightweight css-only tooltips
  Just 1kb minified and gzipped

  @author Ghosh
  @package Microtip

----------------------------------------------------------------------
  1. Base Styles
  2. Direction Modifiers
  3. Position Modifiers
--------------------------------------------------------------------*/
/* ------------------------------------------------
  [1] Base Styles
-------------------------------------------------*/
.uppy-Root [aria-label][role~="tooltip"] {
  /* no important */
  position: relative; }

.uppy-Root [aria-label][role~="tooltip"]::before,
.uppy-Root [aria-label][role~="tooltip"]::after {
  /* no important */
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.18s ease-in-out 0s;
  transition: all 0.18s ease-in-out 0s;
  -webkit-transition: all var(--microtip-transition-duration, 0.18s) var(--microtip-transition-easing, ease-in-out) var(--microtip-transition-delay, 0s);
  transition: all var(--microtip-transition-duration, 0.18s) var(--microtip-transition-easing, ease-in-out) var(--microtip-transition-delay, 0s);
  position: absolute;
  box-sizing: border-box;
  z-index: 10;
  -webkit-transform-origin: top;
          transform-origin: top; }

.uppy-Root [aria-label][role~="tooltip"]::before {
  /* no important */
  background-size: 100% auto !important;
  content: ""; }

.uppy-Root [aria-label][role~="tooltip"]::after {
  /* no important */
  background: rgba(17, 17, 17, 0.9);
  border-radius: 4px;
  color: #ffffff;
  content: attr(aria-label);
  font-size: 13px;
  font-size: var(--microtip-font-size, 13px);
  font-weight: normal;
  font-weight: var(--microtip-font-weight, normal);
  text-transform: none;
  text-transform: var(--microtip-text-transform, none);
  padding: .5em 1em;
  white-space: nowrap;
  box-sizing: content-box; }

.uppy-Root [aria-label][role~="tooltip"]:hover::before,
.uppy-Root [aria-label][role~="tooltip"]:hover::after,
.uppy-Root [aria-label][role~="tooltip"]:focus::before,
.uppy-Root [aria-label][role~="tooltip"]:focus::after {
  /* no important */
  opacity: 1;
  pointer-events: auto; }

/* ------------------------------------------------
  [2] Position Modifiers
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position|="top"]::before {
  /* no important */
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%280%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  height: 6px;
  width: 18px;
  margin-bottom: 5px; }

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]::after {
  /* no important */
  margin-bottom: 11px; }

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]::before {
  /* no important */
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
  bottom: 100%;
  left: 50%; }

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]:hover::before {
  /* no important */
  -webkit-transform: translate3d(-50%, -5px, 0);
          transform: translate3d(-50%, -5px, 0); }

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]::after {
  /* no important */
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
  bottom: 100%;
  left: 50%; }

.uppy-Root [role~="tooltip"][data-microtip-position="top"]:hover::after {
  /* no important */
  -webkit-transform: translate3d(-50%, -5px, 0);
          transform: translate3d(-50%, -5px, 0); }

/* ------------------------------------------------
  [2.1] Top Left
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position="top-left"]::after {
  /* no important */
  -webkit-transform: translate3d(calc(-100% + 16px), 0, 0);
          transform: translate3d(calc(-100% + 16px), 0, 0);
  bottom: 100%; }

.uppy-Root [role~="tooltip"][data-microtip-position="top-left"]:hover::after {
  /* no important */
  -webkit-transform: translate3d(calc(-100% + 16px), -5px, 0);
          transform: translate3d(calc(-100% + 16px), -5px, 0); }

/* ------------------------------------------------
  [2.2] Top Right
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position="top-right"]::after {
  /* no important */
  -webkit-transform: translate3d(calc(0% + -16px), 0, 0);
          transform: translate3d(calc(0% + -16px), 0, 0);
  bottom: 100%; }

.uppy-Root [role~="tooltip"][data-microtip-position="top-right"]:hover::after {
  /* no important */
  -webkit-transform: translate3d(calc(0% + -16px), -5px, 0);
          transform: translate3d(calc(0% + -16px), -5px, 0); }

/* ------------------------------------------------
  [2.3] Bottom
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]::before {
  /* no important */
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28180%2018%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  height: 6px;
  width: 18px;
  margin-top: 5px;
  margin-bottom: 0; }

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]::after {
  /* no important */
  margin-top: 11px; }

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]::before {
  /* no important */
  -webkit-transform: translate3d(-50%, -10px, 0);
          transform: translate3d(-50%, -10px, 0);
  bottom: auto;
  left: 50%;
  top: 100%; }

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]:hover::before {
  /* no important */
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0); }

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]::after {
  /* no important */
  -webkit-transform: translate3d(-50%, -10px, 0);
          transform: translate3d(-50%, -10px, 0);
  top: 100%;
  left: 50%; }

.uppy-Root [role~="tooltip"][data-microtip-position="bottom"]:hover::after {
  /* no important */
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0); }

/* ------------------------------------------------
  [2.4] Bottom Left
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position="bottom-left"]::after {
  /* no important */
  -webkit-transform: translate3d(calc(-100% + 16px), -10px, 0);
          transform: translate3d(calc(-100% + 16px), -10px, 0);
  top: 100%; }

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-left"]:hover::after {
  /* no important */
  -webkit-transform: translate3d(calc(-100% + 16px), 0, 0);
          transform: translate3d(calc(-100% + 16px), 0, 0); }

/* ------------------------------------------------
  [2.5] Bottom Right
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position="bottom-right"]::after {
  /* no important */
  -webkit-transform: translate3d(calc(0% + -16px), -10px, 0);
          transform: translate3d(calc(0% + -16px), -10px, 0);
  top: 100%; }

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-right"]:hover::after {
  /* no important */
  -webkit-transform: translate3d(calc(0% + -16px), 0, 0);
          transform: translate3d(calc(0% + -16px), 0, 0); }

/* ------------------------------------------------
  [2.6] Left
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position="left"]::before,
.uppy-Root [role~="tooltip"][data-microtip-position="left"]::after {
  /* no important */
  bottom: auto;
  left: auto;
  right: 100%;
  top: 50%;
  -webkit-transform: translate3d(10px, -50%, 0);
          transform: translate3d(10px, -50%, 0); }

.uppy-Root [role~="tooltip"][data-microtip-position="left"]::before {
  /* no important */
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28-90%2018%2018%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  height: 18px;
  width: 6px;
  margin-right: 5px;
  margin-bottom: 0; }

.uppy-Root [role~="tooltip"][data-microtip-position="left"]::after {
  /* no important */
  margin-right: 11px; }

.uppy-Root [role~="tooltip"][data-microtip-position="left"]:hover::before,
.uppy-Root [role~="tooltip"][data-microtip-position="left"]:hover::after {
  /* no important */
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0); }

/* ------------------------------------------------
  [2.7] Right
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position="right"]::before,
.uppy-Root [role~="tooltip"][data-microtip-position="right"]::after {
  /* no important */
  bottom: auto;
  left: 100%;
  top: 50%;
  -webkit-transform: translate3d(-10px, -50%, 0);
          transform: translate3d(-10px, -50%, 0); }

.uppy-Root [role~="tooltip"][data-microtip-position="right"]::before {
  /* no important */
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%2890%206%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  height: 18px;
  width: 6px;
  margin-bottom: 0;
  margin-left: 5px; }

.uppy-Root [role~="tooltip"][data-microtip-position="right"]::after {
  /* no important */
  margin-left: 11px; }

.uppy-Root [role~="tooltip"][data-microtip-position="right"]:hover::before,
.uppy-Root [role~="tooltip"][data-microtip-position="right"]:hover::after {
  /* no important */
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0); }

/* ------------------------------------------------
  [3] Size
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-size="small"]::after {
  /* no important */
  white-space: normal;
  white-space: initial;
  width: 80px; }

.uppy-Root [role~="tooltip"][data-microtip-size="medium"]::after {
  /* no important */
  white-space: normal;
  white-space: initial;
  width: 150px; }

.uppy-Root [role~="tooltip"][data-microtip-size="large"]::after {
  /* no important */
  white-space: normal;
  white-space: initial;
  width: 260px; }

.uppy-StatusBar {
  display: -webkit-flex;
  display: flex;
  position: relative;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  background-color: #fff;
  z-index: 1001;
  -webkit-transition: height .2s;
  transition: height .2s; }

.uppy-size--md .uppy-StatusBar {
  height: 46px; }

.uppy-StatusBar:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #eaeaea; }

.uppy-StatusBar[aria-hidden=true] {
  overflow-y: hidden;
  height: 0; }

.uppy-StatusBar.is-complete .uppy-StatusBar-progress {
  background-color: #1bb240; }

.uppy-StatusBar.is-error .uppy-StatusBar-progress {
  background-color: #e32437; }

.uppy-StatusBar.is-complete .uppy-StatusBar-statusIndicator {
  color: #1bb240; }

.uppy-StatusBar.is-error .uppy-StatusBar-statusIndicator {
  color: #e32437; }

.uppy-StatusBar:not([aria-hidden=true]).is-waiting {
  background-color: #fff;
  height: 65px;
  border-top: 1px solid #eaeaea; }

.uppy-StatusBar-progress {
  background-color: #2275d7;
  height: 2px;
  position: absolute;
  z-index: 1001;
  -webkit-transition: background-color, width .3s ease-out;
  transition: background-color, width .3s ease-out; }
  .uppy-StatusBar-progress.is-indeterminate {
    background-size: 64px 64px;
    background-image: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.3) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.3) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.3) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.3) 75%, transparent 75%, transparent);
    -webkit-animation: uppy-StatusBar-ProgressStripes 1s linear infinite;
            animation: uppy-StatusBar-ProgressStripes 1s linear infinite; }

@-webkit-keyframes uppy-StatusBar-ProgressStripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 64px 0; } }

@keyframes uppy-StatusBar-ProgressStripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 64px 0; } }

.uppy-StatusBar.is-preprocessing .uppy-StatusBar-progress,
.uppy-StatusBar.is-postprocessing .uppy-StatusBar-progress {
  background-color: #f6a623; }

.uppy-StatusBar.is-waiting .uppy-StatusBar-progress {
  display: none; }

.uppy-StatusBar-content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  z-index: 1002;
  padding-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333;
  height: 100%; }
  .uppy-size--md .uppy-StatusBar-content {
    padding-left: 15px; }

.uppy-StatusBar-status {
  line-height: 1.4;
  font-weight: normal;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  padding-right: 0.3em; }

.uppy-Root:not(.uppy-size--md) .uppy-StatusBar-additionalInfo {
  display: none; }

.uppy-StatusBar-statusPrimary {
  font-weight: 500;
  line-height: 1; }

.uppy-StatusBar-statusSecondary {
  margin-top: 1px;
  font-size: 11px;
  line-height: 1.2;
  display: inline-block;
  color: #757575;
  white-space: nowrap; }

.uppy-StatusBar-statusSecondaryHint {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  line-height: 1; }
  .uppy-size--md .uppy-StatusBar-statusSecondaryHint {
    margin-right: 8px; }

.uppy-StatusBar-statusIndicator {
  position: relative;
  top: 1px;
  color: #525252;
  margin-right: 7px; }
  .uppy-StatusBar-statusIndicator svg {
    vertical-align: text-bottom; }

.uppy-StatusBar-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  z-index: 1004; }

.uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
  width: 100%;
  position: static;
  padding: 0 15px;
  background-color: #fafafa; }

.uppy-StatusBar-actionCircleBtn {
  line-height: 1;
  cursor: pointer;
  margin: 3px;
  opacity: 0.9; }
  .uppy-StatusBar-actionCircleBtn:focus {
    outline: none; }
  .uppy-StatusBar-actionCircleBtn::-moz-focus-inner {
    border: 0; }
  .uppy-StatusBar-actionCircleBtn:focus {
    box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5); }
  .uppy-StatusBar-actionCircleBtn:hover {
    opacity: 1; }
  .uppy-StatusBar-actionCircleBtn:focus {
    border-radius: 50%; }

.uppy-StatusBar-actionCircleBtn svg {
  vertical-align: bottom; }

.uppy-StatusBar-actionBtn {
  display: inline-block;
  vertical-align: middle;
  font-size: 10px;
  line-height: inherit;
  color: #2275d7; }
  .uppy-size--md .uppy-StatusBar-actionBtn {
    font-size: 11px; }

.uppy-StatusBar-actionBtn--retry {
  height: 16px;
  border-radius: 8px;
  margin-right: 6px;
  background-color: #ff4b23;
  line-height: 1;
  color: #fff;
  padding: 1px 6px 3px 18px;
  position: relative; }
  .uppy-StatusBar-actionBtn--retry:focus {
    outline: none; }
  .uppy-StatusBar-actionBtn--retry::-moz-focus-inner {
    border: 0; }
  .uppy-StatusBar-actionBtn--retry:focus {
    box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5); }
  .uppy-StatusBar-actionBtn--retry:hover {
    background-color: #f92d00; }
  .uppy-StatusBar-actionBtn--retry svg {
    position: absolute;
    top: 3px;
    left: 6px; }

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  font-size: 14px;
  width: 100%;
  padding: 15px 10px;
  color: #fff;
  background-color: #1bb240;
  line-height: 1; }

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
  background-color: #148630; }

.uppy-size--md .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  padding: 13px 22px;
  width: auto; }

.uppy-StatusBar:not(.is-waiting) .uppy-StatusBar-actionBtn--upload {
  background-color: transparent;
  color: #2275d7; }

.uppy-StatusBar-actionBtn--uploadNewlyAdded {
  padding-right: 3px;
  padding-left: 3px;
  padding-bottom: 1px;
  border-radius: 3px; }
  .uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
    outline: none; }
  .uppy-StatusBar-actionBtn--uploadNewlyAdded::-moz-focus-inner {
    border: 0; }
  .uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
    box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5); }

.uppy-StatusBar-details {
  line-height: 12px;
  width: 13px;
  height: 13px;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  background-color: #939393;
  border-radius: 50%;
  position: relative;
  top: 0;
  left: 2px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  cursor: help; }

.uppy-StatusBar-details:after {
  line-height: 1.3;
  word-wrap: break-word; }

.uppy-StatusBar-spinner {
  -webkit-animation-name: uppy-StatusBar-spinnerAnimation;
          animation-name: uppy-StatusBar-spinnerAnimation;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  margin-right: 10px;
  fill: #2275d7; }

.uppy-StatusBar.is-preprocessing .uppy-StatusBar-spinner,
.uppy-StatusBar.is-postprocessing .uppy-StatusBar-spinner {
  fill: #f6a623; }

@-webkit-keyframes uppy-StatusBar-spinnerAnimation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes uppy-StatusBar-spinnerAnimation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.uppy-ProviderBrowser-viewType--grid ul.uppy-ProviderBrowser-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: 6px; }
  .uppy-ProviderBrowser-viewType--grid ul.uppy-ProviderBrowser-list::after {
    content: '';
    -webkit-flex: auto;
            flex: auto; }

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem {
  width: 50%;
  position: relative;
  margin: 0; }
  .uppy-size--md .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem {
    width: 33.3333%; }
  .uppy-size--lg .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem {
    width: 25%; }
  .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem::before {
    content: '';
    padding-top: 100%;
    display: block; }

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--selected img, .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--selected svg {
  opacity: 0.85; }

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner {
  background-color: rgba(147, 147, 147, 0.2); }

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview svg {
  fill: rgba(0, 0, 0, 0.7);
  width: 30%;
  height: 30%; }

.uppy-ProviderBrowser-viewType--grid button.uppy-ProviderBrowserItem-inner {
  border-radius: 4px;
  overflow: hidden;
  position: absolute;
  top: 7px;
  left: 7px;
  right: 7px;
  bottom: 7px;
  text-align: center;
  width: calc(100% - 14px);
  height: calc(100% - 14px); }
  .uppy-ProviderBrowser-viewType--grid button.uppy-ProviderBrowserItem-inner:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.9); }
  .uppy-ProviderBrowser-viewType--grid button.uppy-ProviderBrowserItem-inner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-fakeCheckbox {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 26px;
  height: 26px;
  background-color: #2275d7;
  border-radius: 50%;
  z-index: 1002;
  opacity: 0; }
  .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-fakeCheckbox:after {
    width: 12px;
    height: 7px;
    left: 7px;
    top: 8px; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-fakeCheckbox--is-checked {
  opacity: 1; }

.uppy-ProviderBrowser-viewType--list {
  background-color: #fff; }
  .uppy-ProviderBrowser-viewType--list li.uppy-ProviderBrowserItem {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 7px 15px;
    margin: 0; }
  .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-fakeCheckbox {
    margin-right: 15px;
    height: 17px;
    width: 17px;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #cfcfcf; }
    .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-fakeCheckbox:focus {
      border: 1px solid #2275d7;
      box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.25);
      outline: none; }
    .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-fakeCheckbox::after {
      opacity: 0;
      height: 5px;
      width: 9px;
      left: 3px;
      top: 4px; }
  .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-fakeCheckbox--is-checked {
    background-color: #2275d7;
    border-color: #2275d7; }
    .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-fakeCheckbox--is-checked::after {
      opacity: 1; }
  .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 2px; }
    .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner:focus {
      outline: none;
      text-decoration: underline; }
    .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner img, .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner svg {
      margin-right: 8px;
      max-width: 20px;
      max-height: 20px; }

.uppy-ProviderBrowserItem-fakeCheckbox {
  position: relative;
  cursor: pointer;
  -webkit-flex-shrink: 0;
          flex-shrink: 0; }
  .uppy-ProviderBrowserItem-fakeCheckbox::after {
    content: '';
    position: absolute;
    cursor: pointer;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }

.uppy-DashboardContent-panelBody {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex: 1 1;
          flex: 1 1; }

.uppy-Provider-auth,
.uppy-Provider-error,
.uppy-Provider-loading,
.uppy-Provider-empty {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  -webkit-flex: 1 1;
          flex: 1 1;
  color: #939393; }

.uppy-Provider-empty {
  color: #939393; }

.uppy-Provider-authIcon svg {
  width: 100px;
  height: 75px;
  margin-bottom: 15px; }

.uppy-Provider-authTitle {
  font-size: 17px;
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 30px;
  padding: 0 15px;
  max-width: 500px;
  text-align: center;
  color: #757575; }
  .uppy-size--md .uppy-Provider-authTitle {
    font-size: 20px; }

.uppy-Provider-breadcrumbs {
  -webkit-flex: 1 1;
          flex: 1 1;
  color: #525252;
  font-size: 12px;
  margin-bottom: 10px;
  text-align: left; }
  .uppy-size--md .uppy-Provider-breadcrumbs {
    margin-bottom: 0; }

.uppy-Provider-breadcrumbsIcon {
  display: inline-block;
  color: #525252;
  vertical-align: middle;
  margin-right: 4px;
  line-height: 1; }

.uppy-Provider-breadcrumbsIcon svg {
  width: 13px;
  height: 13px;
  fill: #525252; }

.uppy-Provider-breadcrumbs button {
  display: inline-block;
  line-height: inherit;
  padding: 4px;
  border-radius: 3px; }
  .uppy-Provider-breadcrumbs button:focus {
    outline: none; }
  .uppy-Provider-breadcrumbs button::-moz-focus-inner {
    border: 0; }
  .uppy-Provider-breadcrumbs button:hover {
    color: #1b5dab; }
  .uppy-Provider-breadcrumbs button:focus {
    background-color: #eceef2; }
  .uppy-Provider-breadcrumbs button:hover {
    text-decoration: underline;
    cursor: pointer; }

.uppy-ProviderBrowser {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  font-size: 14px;
  font-weight: 400;
  height: 100%; }

.uppy-ProviderBrowser-user {
  margin: 0 8px 0 0;
  font-weight: 500;
  color: #333; }

.uppy-ProviderBrowser-user:after {
  content: '\B7';
  position: relative;
  left: 4px;
  color: #939393;
  font-weight: normal; }

.uppy-ProviderBrowser-header {
  z-index: 1001;
  border-bottom: 1px solid #eaeaea;
  position: relative; }

.uppy-ProviderBrowser-headerBar {
  padding: 7px 15px;
  background-color: #fafafa;
  z-index: 1001;
  color: #757575;
  line-height: 1.4;
  font-size: 12px; }
  .uppy-size--md .uppy-ProviderBrowser-headerBar {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }

.uppy-ProviderBrowser-headerBar--simple {
  text-align: center;
  display: block;
  -webkit-justify-content: center;
          justify-content: center; }

.uppy-ProviderBrowser-headerBar--simple .uppy-Provider-breadcrumbsWrap {
  -webkit-flex: none;
          flex: none;
  display: inline-block;
  vertical-align: middle; }

.uppy-ProviderBrowser-search {
  width: 100%;
  background-color: #fff;
  position: relative;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.uppy-ProviderBrowser-searchIcon {
  position: absolute;
  width: 12px;
  height: 12px;
  left: 16px;
  z-index: 1002;
  color: #bbb; }

.uppy-ProviderBrowser-searchInput {
  width: 100%;
  height: 30px;
  background-color: transparent;
  outline: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 12px;
  line-height: 1.4;
  border: 0;
  margin: 0 8px;
  padding-left: 27px;
  z-index: 1001;
  border-radius: 4px; }

.uppy-ProviderBrowser-searchInput:focus {
  outline: 0;
  background-color: #f4f4f4; }

.uppy-ProviderBrowser-searchClose {
  position: absolute;
  width: 22px;
  height: 22px;
  padding: 6px;
  right: 12px;
  top: 4px;
  z-index: 1002;
  color: #939393;
  cursor: pointer; }
  .uppy-ProviderBrowser-searchClose:hover {
    color: #757575; }

.uppy-ProviderBrowser-searchClose svg {
  vertical-align: text-top; }

.uppy-ProviderBrowser-searchInput::-webkit-input-placeholder {
  color: #939393;
  opacity: 1; }

.uppy-ProviderBrowser-searchInput::-ms-input-placeholder {
  color: #939393;
  opacity: 1; }

.uppy-ProviderBrowser-searchInput::placeholder {
  color: #939393;
  opacity: 1; }

.uppy-ProviderBrowser-userLogout {
  cursor: pointer;
  line-height: inherit;
  color: #2275d7;
  padding: 4px;
  border-radius: 3px; }
  .uppy-ProviderBrowser-userLogout:focus {
    outline: none; }
  .uppy-ProviderBrowser-userLogout::-moz-focus-inner {
    border: 0; }
  .uppy-ProviderBrowser-userLogout:hover {
    color: #1b5dab; }
  .uppy-ProviderBrowser-userLogout:focus {
    background-color: #eceef2; }
  .uppy-ProviderBrowser-userLogout:hover {
    text-decoration: underline; }

.uppy-ProviderBrowser-body {
  -webkit-flex: 1 1;
          flex: 1 1;
  position: relative; }

.uppy-ProviderBrowser-list {
  -webkit-flex: 1 1;
          flex: 1 1;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-spacing: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  list-style: none;
  margin: 0;
  padding: 0; }

.uppy-ProviderBrowserItem-inner {
  cursor: pointer;
  font-weight: 500;
  font-size: 13px; }

.uppy-ProviderBrowser-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background: #fff;
  height: 65px;
  border-top: 1px solid #eaeaea;
  padding: 0 15px; }
  .uppy-ProviderBrowser-footer button {
    margin-right: 8px; }

.uppy-DashboardItem-previewInnerWrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
  border-radius: 3px; }
  .uppy-size--md .uppy-DashboardItem-previewInnerWrap {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15); }

.uppy-DashboardItem-previewInnerWrap:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
  display: none;
  z-index: 1001; }

.uppy-DashboardItem-previewLink {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1002; }
  .uppy-DashboardItem-previewLink:focus {
    box-shadow: inset 0 0 0 3px #76abe9; }

.uppy-DashboardItem-preview img.uppy-DashboardItem-previewImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  border-radius: 3px; }

.uppy-DashboardItem-progress {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1002;
  color: #fff;
  text-align: center;
  width: 120px;
  -webkit-transition: all .35 ease;
  transition: all .35 ease; }

.uppy-DashboardItem-progressIndicator {
  display: inline-block;
  width: 38px;
  height: 38px;
  opacity: 0.9;
  cursor: pointer; }
  .uppy-DashboardItem-progressIndicator:focus {
    outline: none; }
  .uppy-DashboardItem-progressIndicator::-moz-focus-inner {
    border: 0; }
  .uppy-DashboardItem-progressIndicator:focus svg.UppyIcon-progressCircle .bg,
  .uppy-DashboardItem-progressIndicator:focus svg.retry {
    fill: #76abe9; }

svg.UppyIcon-progressCircle {
  width: 100%;
  height: 100%; }
  svg.UppyIcon-progressCircle .bg {
    stroke: rgba(255, 255, 255, 0.4);
    opacity: 0; }
  svg.UppyIcon-progressCircle .progress {
    stroke: #fff;
    -webkit-transition: stroke-dashoffset .5s ease-out;
    transition: stroke-dashoffset .5s ease-out;
    opacity: 0; }
  svg.UppyIcon-progressCircle .play {
    stroke: #fff;
    fill: #fff;
    opacity: 0;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    display: none; }
  svg.UppyIcon-progressCircle .cancel {
    fill: #fff;
    opacity: 0;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
  svg.UppyIcon-progressCircle .pause {
    stroke: #fff;
    fill: #fff;
    opacity: 0;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    display: none; }
  svg.UppyIcon-progressCircle .check {
    opacity: 0;
    fill: #fff;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }

svg.UppyIcon.retry {
  fill: #fff; }

.uppy-DashboardItem.is-complete .uppy-DashboardItem-progress {
  -webkit-transform: none;
          transform: none;
  -webkit-transform: initial;
          transform: initial;
  top: -9px;
  right: -8px;
  left: auto;
  left: initial;
  width: auto; }

.uppy-DashboardItem.is-inprogress .uppy-DashboardItem-progress,
.uppy-DashboardItem.is-complete .uppy-DashboardItem-progress,
.uppy-DashboardItem.is-error .uppy-DashboardItem-progress {
  display: block; }

.uppy-DashboardItem.is-error .uppy-DashboardItem-progressIndicator {
  width: 18px;
  height: 18px; }
  .uppy-size--md .uppy-DashboardItem.is-error .uppy-DashboardItem-progressIndicator {
    width: 28px;
    height: 28px; }

.uppy-DashboardItem.is-complete .uppy-DashboardItem-progressIndicator {
  width: 18px;
  height: 18px;
  opacity: 1; }
  .uppy-size--md .uppy-DashboardItem.is-complete .uppy-DashboardItem-progressIndicator {
    width: 22px;
    height: 22px; }

.uppy-DashboardItem.is-paused svg.UppyIcon-progressCircle .pause {
  opacity: 0; }

.uppy-DashboardItem.is-paused svg.UppyIcon-progressCircle .play {
  opacity: 1; }

.uppy-DashboardItem.is-noIndividualCancellation .uppy-DashboardItem-progressIndicator {
  cursor: default; }

.uppy-DashboardItem.is-noIndividualCancellation .cancel {
  display: none; }

.uppy-DashboardItem.is-processing .uppy-DashboardItem-progress {
  opacity: 0; }

.uppy-DashboardItem.is-complete .uppy-DashboardItem-progressIndicator {
  cursor: default; }

.uppy-DashboardItem.is-complete .progress {
  stroke: #1bb240;
  fill: #1bb240;
  opacity: 1; }

.uppy-DashboardItem.is-complete .check {
  opacity: 1; }

.uppy-size--md .uppy-DashboardItem-progressIndicator {
  width: 55px;
  height: 55px; }

.uppy-DashboardItem.is-resumable .pause, .uppy-DashboardItem.is-resumable .play {
  display: block; }

.uppy-DashboardItem.is-resumable .cancel {
  display: none; }

.uppy-DashboardItem.is-inprogress .bg, .uppy-DashboardItem.is-inprogress .progress, .uppy-DashboardItem.is-inprogress .pause, .uppy-DashboardItem.is-inprogress .cancel {
  opacity: 1; }

.uppy-DashboardItem-fileInfo {
  padding-right: 5px; }

.uppy-DashboardItem-name {
  font-size: 12px;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 4px;
  word-break: break-all;
  word-wrap: anywhere; }

.uppy-DashboardItem-status {
  font-size: 11px;
  line-height: 1.3;
  font-weight: normal;
  color: #757575; }

.uppy-DashboardItem-statusSize {
  display: inline-block;
  vertical-align: bottom;
  text-transform: uppercase; }

.uppy-DashboardItem-sourceIcon {
  display: none;
  vertical-align: bottom;
  color: #bbb; }
  .uppy-DashboardItem-sourceIcon:not(:first-child) {
    position: relative;
    margin-left: 14px; }
  .uppy-DashboardItem-sourceIcon svg,
  .uppy-DashboardItem-sourceIcon svg * {
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    vertical-align: text-bottom;
    overflow: hidden;
    fill: currentColor;
    width: 11px;
    height: 12px; }

.uppy-DashboardItem-action {
  cursor: pointer;
  color: #939393; }
  .uppy-DashboardItem-action:focus {
    outline: none; }
  .uppy-DashboardItem-action::-moz-focus-inner {
    border: 0; }
  .uppy-DashboardItem-action:focus {
    box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5); }
  .uppy-DashboardItem-action:hover {
    opacity: 1;
    color: #1f1f1f; }

.uppy-DashboardItem-action--remove {
  color: #1f1f1f;
  opacity: 0.95; }

.uppy-Dashboard:not(.uppy-size--md) .uppy-DashboardItem-actionWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.uppy-Dashboard:not(.uppy-size--md) .uppy-DashboardItem-action {
  width: 22px;
  height: 22px;
  padding: 3px;
  margin-left: 3px; }
  .uppy-Dashboard:not(.uppy-size--md) .uppy-DashboardItem-action:focus {
    border-radius: 3px; }

.uppy-size--md .uppy-DashboardItem-action--copyLink,
.uppy-size--md .uppy-DashboardItem-action--edit {
  width: 16px;
  height: 16px;
  padding: 0; }
  .uppy-size--md .uppy-DashboardItem-action--copyLink:focus,
  .uppy-size--md .uppy-DashboardItem-action--edit:focus {
    border-radius: 3px; }

.uppy-size--md .uppy-DashboardItem-action--remove {
  z-index: 1002;
  position: absolute;
  top: -8px;
  right: -8px;
  width: 18px;
  height: 18px;
  padding: 0; }
  .uppy-size--md .uppy-DashboardItem-action--remove:focus {
    border-radius: 50%; }

.uppy-Dashboard:not(.uppy-size--md) .uppy-DashboardItem {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding: 10px;
  padding-right: 0; }

.uppy-size--md .uppy-DashboardItem {
  position: relative;
  display: inline-block;
  margin: 5px 15px;
  width: calc(33.333% - 15px - 15px);
  height: 215px; }

.uppy-size--lg .uppy-DashboardItem {
  margin: 5px 15px;
  width: calc(25% - 15px - 15px);
  height: 190px; }

.uppy-size--xl .uppy-DashboardItem {
  width: calc(20% - 15px - 15px);
  height: 210px; }

.uppy-DashboardItem-preview {
  position: relative; }
  .uppy-Dashboard:not(.uppy-size--md) .uppy-DashboardItem-preview {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    width: 50px;
    height: 50px; }
  .uppy-size--md .uppy-DashboardItem-preview {
    width: 100%;
    height: 140px; }
  .uppy-size--lg .uppy-DashboardItem-preview {
    height: 120px; }
  .uppy-size--xl .uppy-DashboardItem-preview {
    height: 140px; }

.uppy-DashboardItem-fileInfoAndButtons {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  padding-right: 8px;
  padding-left: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .uppy-size--md .uppy-DashboardItem-fileInfoAndButtons {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    width: 100%;
    padding: 0;
    padding-top: 9px; }

.uppy-DashboardItem-fileInfo {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 1;
          flex-shrink: 1; }

.uppy-DashboardItem-actionWrapper {
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0; }

.uppy-DashboardItem.is-inprogress .uppy-DashboardItem-previewInnerWrap:after {
  display: block; }

.uppy-DashboardItem.is-error .uppy-DashboardItem-previewInnerWrap:after {
  display: block; }

.uppy-DashboardItem.is-inprogress:not(.is-resumable) .uppy-DashboardItem-action--remove {
  display: none; }

.uppy-Dashboard-FileCard {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1005;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .uppy-Dashboard-FileCard .uppy-DashboardContent-bar {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
  .uppy-Dashboard-FileCard .uppy-Dashboard-FileCard-actions {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; }

.uppy-Dashboard-FileCard-inner {
  height: 100%;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  min-height: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.uppy-Dashboard-FileCard-preview {
  height: 60%;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  min-height: 0;
  border-bottom: 1px solid #eaeaea;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.uppy-Dashboard-FileCard-preview img.uppy-DashboardItem-previewImg {
  max-width: 90%;
  max-height: 90%;
  object-fit: cover;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  border-radius: 3px;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15); }

.uppy-Dashboard-FileCard-info {
  height: 40%;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  padding: 30px 20px 20px 20px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.uppy-Dashboard-FileCard-fieldset {
  font-size: 0;
  border: 0;
  padding: 0;
  max-width: 640px;
  margin: auto;
  margin-bottom: 12px; }

.uppy-Dashboard-FileCard-label {
  display: inline-block;
  vertical-align: middle;
  width: 22%;
  font-size: 12px;
  color: #525252; }
  .uppy-size--md .uppy-Dashboard-FileCard-label {
    font-size: 14px; }

.uppy-Dashboard-FileCard-input {
  display: inline-block;
  vertical-align: middle;
  width: 78%; }

.uppy-Dashboard-FileCard-actions {
  height: 55px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  border-top: 1px solid #eaeaea;
  padding: 0 15px;
  background-color: #fafafa;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .uppy-size--md .uppy-Dashboard-FileCard-actions {
    height: 65px; }

.uppy-Dashboard-FileCard-actionsBtn {
  margin-right: 10px; }

.uppy-transition-slideDownUp-enter {
  opacity: 0.01;
  -webkit-transform: translate3d(0, -105%, 0);
          transform: translate3d(0, -105%, 0);
  -webkit-transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out; }

.uppy-transition-slideDownUp-enter.uppy-transition-slideDownUp-enter-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

.uppy-transition-slideDownUp-leave {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out; }

.uppy-transition-slideDownUp-leave.uppy-transition-slideDownUp-leave-active {
  opacity: 0.01;
  -webkit-transform: translate3d(0, -105%, 0);
          transform: translate3d(0, -105%, 0); }

.uppy-Dashboard--modal {
  z-index: 1001; }

.uppy-Dashboard--modal[aria-hidden=true] {
  display: none; }

@-webkit-keyframes uppy-Dashboard-fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes uppy-Dashboard-fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes uppy-Dashboard-fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes uppy-Dashboard-fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes uppy-Dashboard-slideDownAndFadeIn {
  from {
    -webkit-transform: translate3d(-50%, -70%, 0);
            transform: translate3d(-50%, -70%, 0);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
    opacity: 1; } }

@keyframes uppy-Dashboard-slideDownAndFadeIn {
  from {
    -webkit-transform: translate3d(-50%, -70%, 0);
            transform: translate3d(-50%, -70%, 0);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
    opacity: 1; } }

@-webkit-keyframes uppy-Dashboard-slideDownAndFadeIn--small {
  from {
    -webkit-transform: translate3d(0, -20%, 0);
            transform: translate3d(0, -20%, 0);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes uppy-Dashboard-slideDownAndFadeIn--small {
  from {
    -webkit-transform: translate3d(0, -20%, 0);
            transform: translate3d(0, -20%, 0);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; } }

@-webkit-keyframes uppy-Dashboard-slideUpFadeOut {
  from {
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
    opacity: 1; }
  to {
    -webkit-transform: translate3d(-50%, -70%, 0);
            transform: translate3d(-50%, -70%, 0);
    opacity: 0; } }

@keyframes uppy-Dashboard-slideUpFadeOut {
  from {
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
    opacity: 1; }
  to {
    -webkit-transform: translate3d(-50%, -70%, 0);
            transform: translate3d(-50%, -70%, 0);
    opacity: 0; } }

@-webkit-keyframes uppy-Dashboard-slideUpFadeOut--small {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; }
  to {
    -webkit-transform: translate3d(0, -20%, 0);
            transform: translate3d(0, -20%, 0);
    opacity: 0; } }

@keyframes uppy-Dashboard-slideUpFadeOut--small {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; }
  to {
    -webkit-transform: translate3d(0, -20%, 0);
            transform: translate3d(0, -20%, 0);
    opacity: 0; } }

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-inner {
  -webkit-animation: uppy-Dashboard-slideDownAndFadeIn--small 0.3s cubic-bezier(0, 0, 0.2, 1);
          animation: uppy-Dashboard-slideDownAndFadeIn--small 0.3s cubic-bezier(0, 0, 0.2, 1); }
  @media only screen and (min-width: 820px) {
    .uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-inner {
      -webkit-animation: uppy-Dashboard-slideDownAndFadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
              animation: uppy-Dashboard-slideDownAndFadeIn 0.3s cubic-bezier(0, 0, 0.2, 1); } }

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-overlay {
  -webkit-animation: uppy-Dashboard-fadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
          animation: uppy-Dashboard-fadeIn 0.3s cubic-bezier(0, 0, 0.2, 1); }

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-inner {
  -webkit-animation: uppy-Dashboard-slideUpFadeOut--small 0.3s cubic-bezier(0, 0, 0.2, 1);
          animation: uppy-Dashboard-slideUpFadeOut--small 0.3s cubic-bezier(0, 0, 0.2, 1); }
  @media only screen and (min-width: 820px) {
    .uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-inner {
      -webkit-animation: uppy-Dashboard-slideUpFadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
              animation: uppy-Dashboard-slideUpFadeOut 0.3s cubic-bezier(0, 0, 0.2, 1); } }

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-overlay {
  -webkit-animation: uppy-Dashboard-fadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
          animation: uppy-Dashboard-fadeOut 0.3s cubic-bezier(0, 0, 0.2, 1); }

.uppy-Dashboard-isFixed {
  overflow: hidden;
  height: 100vh; }

.uppy-Dashboard--modal .uppy-Dashboard-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001; }

.uppy-Dashboard-inner {
  position: relative;
  background-color: #fafafa;
  max-width: 100%;
  max-height: 100%;
  outline: none;
  border: 1px solid #eaeaea;
  border-radius: 5px; }
  .uppy-size--md .uppy-Dashboard-inner {
    min-height: auto; }
  @media only screen and (min-width: 820px) {
    .uppy-Dashboard-inner {
      width: 750px;
      height: 550px; } }
  .uppy-Dashboard--modal .uppy-Dashboard-inner {
    z-index: 1002; }

.uppy-Dashboard-innerWrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  opacity: 0; }

.uppy-Dashboard--isInnerWrapVisible .uppy-Dashboard-innerWrap {
  opacity: 1; }

.uppy-Dashboard--modal .uppy-Dashboard-inner {
  position: fixed;
  top: 35px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border: none; }
  @media only screen and (min-width: 820px) {
    .uppy-Dashboard--modal .uppy-Dashboard-inner {
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      box-shadow: 0 5px 15px 4px rgba(0, 0, 0, 0.15); } }

.uppy-Dashboard-close {
  display: block;
  position: absolute;
  top: -33px;
  right: -2px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.9);
  font-size: 27px;
  z-index: 1005; }
  .uppy-Dashboard-close:focus {
    outline: none; }
  .uppy-Dashboard-close::-moz-focus-inner {
    border: 0; }
  .uppy-Dashboard-close:focus {
    color: #8cb8ed; }
  @media only screen and (min-width: 820px) {
    .uppy-Dashboard-close {
      font-size: 35px;
      top: -10px;
      right: -35px; } }

.uppy-DashboardAddFiles {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  max-height: 100%;
  position: relative;
  text-align: center;
  -webkit-flex: 1 1;
          flex: 1 1; }
  .uppy-size--md .uppy-DashboardAddFiles {
    margin: 7px;
    border-radius: 3px;
    border: 1px dashed #dfdfdf; }
  .uppy-Dashboard-AddFilesPanel .uppy-DashboardAddFiles {
    border: none; }
  .uppy-Dashboard--modal .uppy-DashboardAddFiles {
    border-color: #cfcfcf; }

.uppy-DashboardTabs {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: 100%; }
  .uppy-size--md .uppy-DashboardTabs {
    -webkit-align-items: center;
            align-items: center;
    height: auto; }

.uppy-DashboardTabs-title {
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
  color: #525252; }
  .uppy-size--md .uppy-DashboardTabs-title {
    font-size: 16px;
    line-height: 40px; }

.uppy-DashboardAddFiles-info {
  padding-top: 15px;
  padding-bottom: 15px;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  -webkit-flex-basis: 0%;
          flex-basis: 0%;
  display: none; }
  .uppy-size--height-md .uppy-DashboardAddFiles-info {
    display: block; }
  .uppy-size--md .uppy-DashboardAddFiles-info {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    padding-top: 30px;
    padding-bottom: 0; }

.uppy-Dashboard-browse {
  cursor: pointer;
  color: rgba(34, 117, 215, 0.9); }
  .uppy-Dashboard-browse:focus {
    outline: none; }
  .uppy-Dashboard-browse::-moz-focus-inner {
    border: 0; }
  .uppy-Dashboard-browse:hover, .uppy-Dashboard-browse:focus {
    border-bottom: 2px solid #2275d7; }

.uppy-DashboardTabs-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 2;
          flex-grow: 2;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  -webkit-flex-basis: 0%;
          flex-basis: 0%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 10px;
  padding: 2px 0; }
  .uppy-size--md .uppy-DashboardTabs-list {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    max-width: 600px;
    overflow-y: visible;
    overflow-y: initial;
    margin-top: 15px;
    padding-top: 0; }

.uppy-DashboardTab {
  width: 100%;
  display: inline-block;
  text-align: center;
  border-bottom: 1px solid #eaeaea;
  padding: 0px 2px; }
  .uppy-size--md .uppy-DashboardTab {
    width: auto;
    width: initial;
    margin-bottom: 20px;
    border-bottom: none;
    padding: 0; }

.uppy-DashboardTab-btn {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  color: #525252;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 12px 15px;
  line-height: 1;
  text-align: center; }
  .uppy-size--md .uppy-DashboardTab-btn {
    width: 86px;
    margin-right: 1px;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 10px 3px;
    border-radius: 5px; }

.uppy-DashboardTab-btn::-moz-focus-inner {
  border: 0; }

.uppy-DashboardTab-btn:hover {
  background-color: #f1f3f6; }

.uppy-DashboardTab-btn:active,
.uppy-DashboardTab-btn:focus {
  background-color: #eceef2;
  outline: none; }

.uppy-DashboardTab-btn svg {
  margin-right: 10px; }
  .uppy-size--md .uppy-DashboardTab-btn svg {
    margin-right: 0; }

.uppy-DashboardTab-btn svg {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  vertical-align: text-top;
  overflow: hidden;
  -webkit-transition: -webkit-transform ease-in-out .15s;
  transition: -webkit-transform ease-in-out .15s;
  transition: transform ease-in-out .15s;
  transition: transform ease-in-out .15s, -webkit-transform ease-in-out .15s; }

.uppy-DashboardTab-name {
  font-size: 14px;
  font-weight: 500; }
  .uppy-size--md .uppy-DashboardTab-name {
    font-size: 11px;
    line-height: 14px;
    margin-top: 8px;
    margin-bottom: 0; }

.uppy-DashboardTab svg {
  width: 18px;
  height: 18px;
  vertical-align: middle; }
  .uppy-size--md .uppy-DashboardTab svg {
    width: 27px;
    height: 27px; }

.uppy-Dashboard-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.uppy-DashboardContent-bar {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  height: 40px;
  width: 100%;
  padding: 0 10px;
  z-index: 1004;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  position: relative;
  border-bottom: 1px solid #eaeaea;
  background-color: #fafafa; }
  .uppy-size--md .uppy-DashboardContent-bar {
    height: 50px;
    padding: 0 15px; }

.uppy-DashboardContent-title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 12px;
  line-height: 40px;
  font-weight: 500;
  width: 100%;
  max-width: 170px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  margin: auto; }
  .uppy-size--md .uppy-DashboardContent-title {
    font-size: 14px;
    line-height: 50px;
    max-width: 300px; }

.uppy-DashboardContent-back {
  background: none;
  -webkit-appearance: none;
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  padding: 0;
  margin: 0;
  border: 0;
  color: inherit;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  color: #2275d7;
  padding: 7px 6px;
  margin-left: -6px; }
  .uppy-DashboardContent-back:focus {
    outline: none; }
  .uppy-DashboardContent-back::-moz-focus-inner {
    border: 0; }
  .uppy-DashboardContent-back:hover {
    color: #1b5dab; }
  .uppy-DashboardContent-back:focus {
    background-color: #eceef2; }
  .uppy-size--md .uppy-DashboardContent-back {
    font-size: 14px; }

.uppy-DashboardContent-addMore {
  background: none;
  -webkit-appearance: none;
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  padding: 0;
  margin: 0;
  border: 0;
  color: inherit;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
  color: #2275d7;
  width: 29px;
  height: 29px;
  padding: 7px 8px;
  margin-right: -5px; }
  .uppy-DashboardContent-addMore:focus {
    outline: none; }
  .uppy-DashboardContent-addMore::-moz-focus-inner {
    border: 0; }
  .uppy-DashboardContent-addMore:hover {
    color: #1b5dab; }
  .uppy-DashboardContent-addMore:focus {
    background-color: #eceef2; }
  .uppy-size--md .uppy-DashboardContent-addMore {
    font-size: 14px;
    width: auto;
    height: auto;
    margin-right: -8px; }

.uppy-DashboardContent-addMore svg {
  vertical-align: baseline;
  margin-right: 4px; }
  .uppy-size--md .uppy-DashboardContent-addMore svg {
    width: 11px;
    height: 11px; }

.uppy-DashboardContent-addMoreCaption {
  display: none; }
  .uppy-size--md .uppy-DashboardContent-addMoreCaption {
    display: inline; }

.uppy-DashboardContent-panel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: whitesmoke;
  overflow: hidden;
  z-index: 1005;
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1; }

.uppy-Dashboard-AddFilesPanel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fafafa;
  background: -webkit-linear-gradient(bottom, #fafafa 35%, rgba(250, 250, 250, 0.85) 100%);
  background: linear-gradient(0deg, #fafafa 35%, rgba(250, 250, 250, 0.85) 100%);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  z-index: 1005;
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.uppy-Dashboard--isAddFilesPanelVisible .uppy-Dashboard-files {
  -webkit-filter: blur(2px);
          filter: blur(2px); }

.uppy-Dashboard-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 12%; }

.uppy-Dashboard-progressBarContainer.is-active {
  z-index: 1004;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.uppy-Dashboard-filesContainer {
  position: relative;
  overflow-y: hidden;
  margin: 0;
  -webkit-flex: 1 1;
          flex: 1 1; }
  .uppy-Dashboard-filesContainer:after {
    content: '';
    display: table;
    clear: both; }

.uppy-Dashboard-files {
  margin: 0;
  padding: 0 0 10px 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-flex: 1 1;
          flex: 1 1; }

.uppy-size--md .uppy-Dashboard-files {
  padding-top: 10px; }

.uppy-Dashboard-dropFilesHereHint {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  visibility: hidden;
  position: absolute;
  top: 7px;
  right: 7px;
  bottom: 7px;
  left: 7px;
  padding-top: 90px;
  border: 1px dashed #2275d7;
  border-radius: 3px;
  z-index: 2000;
  text-align: center;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 1v1C11.85 2 2 11.85 2 24s9.85 22 22 22 22-9.85 22-22S36.15 2 24 2V1zm0 0V0c13.254 0 24 10.746 24 24S37.254 48 24 48 0 37.254 0 24 10.746 0 24 0v1zm7.707 19.293a.999.999 0 1 1-1.414 1.414L25 16.414V34a1 1 0 1 1-2 0V16.414l-5.293 5.293a.999.999 0 1 1-1.414-1.414l7-7a.999.999 0 0 1 1.414 0l7 7z' fill='%232275D7' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  color: #707070;
  font-size: 16px; }

.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-dropFilesHereHint {
  visibility: visible; }

.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-DashboardContent-bar,
.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-files,
.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-progressindicators {
  opacity: 0.15; }

.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-DashboardAddFiles {
  opacity: 0.03; }

.uppy-Dashboard-dropFilesTitle {
  max-width: 300px;
  text-align: center;
  font-size: 16px;
  line-height: 1.35;
  font-weight: 400;
  color: #525252;
  margin: auto;
  padding: 0 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  -webkit-flex-basis: 0%;
          flex-basis: 0%; }
  .uppy-size--md .uppy-Dashboard-dropFilesTitle {
    max-width: 470px;
    font-size: 27px; }

.uppy-Dashboard-note {
  font-size: 14px;
  line-height: 1.25;
  text-align: center;
  color: #757575;
  max-width: 350px;
  margin: auto;
  padding: 0 15px; }
  .uppy-size--md .uppy-Dashboard-note {
    font-size: 16px;
    line-height: 1.35;
    max-width: 600px; }

a.uppy-Dashboard-poweredBy {
  display: inline-block;
  text-align: center;
  font-size: 11px;
  color: #939393;
  text-decoration: none;
  margin-top: 8px; }

.uppy-Dashboard-poweredByIcon {
  stroke: #939393;
  fill: none;
  margin-left: 1px;
  margin-right: 1px;
  position: relative;
  top: 1px;
  opacity: 0.9;
  vertical-align: text-top; }

.uppy-DashboardItem-previewIcon {
  width: 25px;
  height: 25px;
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .uppy-size--md .uppy-DashboardItem-previewIcon {
    width: 38px;
    height: 38px; }
  .uppy-DashboardItem-previewIcon svg {
    width: 100%;
    height: 100%; }

.uppy-DashboardItem-previewIconWrap {
  height: 76px;
  max-height: 75%;
  position: relative; }

.uppy-DashboardItem-previewIconBg {
  width: 100%;
  height: 100%;
  -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 1px 1px);
          filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 1px 1px); }

.uppy-Dashboard-upload {
  position: relative;
  width: 50px;
  height: 50px; }
  .uppy-size--md .uppy-Dashboard-upload {
    width: 60px;
    height: 60px; }

.uppy-Dashboard-upload .UppyIcon {
  position: relative;
  top: 1px;
  width: 50%; }

.uppy-Dashboard-uploadCount {
  position: absolute;
  top: -12px;
  right: -12px;
  background-color: #1bb240;
  color: #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 8px; }
  .uppy-size--md .uppy-Dashboard-uploadCount {
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-size: 9px; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem;
}

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
}

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 80px;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: 85px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 70px;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 70px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__day {
  cursor: pointer;
}

.react-datepicker__day:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}

.react-datepicker__day--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1 {
  color: magenta;
}

.react-datepicker__day--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000;
}

.react-datepicker__day--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover {
  background-color: transparent;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 0;
  vertical-align: middle;
  position: absolute;
  height: 16px;
  width: 16px;
  top: 25%;
  right: 7px;
}

.react-datepicker__close-icon::after {
  background-color: #216ba5;
  border-radius: 50%;
  bottom: 0;
  box-sizing: border-box;
  color: #fff;
  content: "\D7";
  cursor: pointer;
  font-size: 12px;
  height: 16px;
  width: 16px;
  line-height: 1;
  margin: -8px auto 0;
  padding: 2px;
  position: absolute;
  right: 0px;
  text-align: center;
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

